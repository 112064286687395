import React from 'react';
import Layout from '../components/layout/Layout';
import PDFViewer from '../components/PDFViewer';
import { Section, SectionTitle } from '../components/Section';
import capabilitiesStatement from '../pdf/LightFeather-Capabilities-Statement-2023.pdf';
import { Helmet } from 'react-helmet';

const LightfeatherCapabilitiesStatement = () => (
  <Layout>
    <Helmet>
      <meta charSet='utf-8' />
      <title>LightFeather - Capabilities Statement</title>
    </Helmet>
    <Section>
      <SectionTitle headingLevel='h1' className='text-blue'>
        LightFeather Capabilities Statement
      </SectionTitle>
      <PDFViewer pdf={capabilitiesStatement} pdfTitle='LightFeather Capabilities Statement' />
    </Section>
  </Layout>
);

export default LightfeatherCapabilitiesStatement;
